import * as Vue from 'vue'
import App from './App.vue'
import itemList from './components/ItemList.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

import PrimeVue from 'primevue/config';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';

import 'primeicons/primeicons.css';
import 'primevue/resources/themes/saga-purple/theme.css';
import 'primevue/resources/primevue.min.css';

const app = Vue.createApp(App)

app.use(VueAxios, axios)
app.use(PrimeVue)
app.component('itemList', itemList)
app.component('InputText', InputText)
app.component('Button', Button)

app.mount('#app')