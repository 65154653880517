<template>
  	<TransitionGroup name="list" tag="li">
        <ul v-for="item in list" v-bind:key="item.id">
          <li>
            <div class="row">
                  <div class="item">{{ item.item }}</div>
                  <div class="checkmark" @click="removeItemFromList(item.id)"><img class="checkmark_img" :src="localPath + '/img/checkmark.png'" /></div>
            </div>
          </li>
        </ul>
      </TransitionGroup>
      <div class="buttonBackRow">
          <Button class="buttonBack" @click="undoLastCheckMark" label="Undo" />
      </div>          

      <div class="row">
        <InputText id="newItem"  v-model.trim="newItem" />
        <div id="buttonNewRow">
          <Button id="buttonNew" @click="addNewItemToList" icon="pi pi-plus" />
        </div>
      </div>
</template>

<script>
export default {
  name: 'itemList',
  data: function (){
    return {
      list: [],
      savedObject: null,
      newRow: null,
      enabled: true,
      newItem: "",

      // localTest
      // localPath: "http://localhost/shopList/src"
      
      // Prod
      localPath: ""
    }
  },
  beforeMount() {
    console.log("before Mount")
    this.fetchData()
  },
  methods: {
    fetchData(){
        console.log("fetch Data with RandParam")
       this.axios.get(this.localPath + '/php/get.php?id='+Math.random())
        .then(response => {
            console.log(response.data);
            this.list = response.data
          })
    },
    removeItemFromList(id){
      var index = this.list.findIndex(function(i){return i.id === id})
      
      this.axios.get(this.localPath + '/php/delete.php?id='+id)
      this.savedObject = this.list[index];
      this.list.splice(index, 1);
    },
    undoLastCheckMark(){
      this.axios.get(this.localPath + '/php/add.php?item='+this.savedObject.item)
      this.list.push(this.savedObject);
    },
    addNewItemToList(){
      if(this.newItem != ""){

        this.axios.get(this.localPath  + '/php/add.php?item='+this.newItem)
        .then(response => { 
          this.newRow = response.data[0]
          this.list.push(this.newRow)
          this.newItem = ""
        })
      }
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body{
  background-color: thistle;
}

.row{
  width: 98%;
  height: 100px;
}

.buttonBackRow{
  
  width: 100%;
  height: 75px;

  margin-top: 50px;

  text-align: center;
}

.buttonBack{
  width: 30%;

  border-style: solid;
  border-color: BLACK;

 /* background-color: rgb(181, 255, 230); */
  height: 100%;

  line-height: 75px;
  text-align: center;

  font-size: xx-large;

  margin: 0 auto;
}


#newItem{

  margin-top: 50px;

  margin-left: auto;
  margin-right: auto;

  width: 80%;
  height: 100px;

  font-size: xx-large;

  border-style: solid;
  border-width: 3px;
  border-color: black;
}

#buttonNewRow{
  
  margin-top: 50px;

  float: right;
  width: 10%;
  height: 100px;

  text-align: center;
}

#buttonNew{
  width: 100%;

  border-style: solid;
  border-color: BLACK;

  color: WHITE;
  background-color: #44AA44;
  height: 100%;

  line-height: 100px;
  text-align: center;

  font-size: xx-large;

  margin: 0 auto;
}

.item{
  float: left;
  width: 89%;
  background-color: rgb(250, 252, 180);
  height: 100%;

  border-style: solid;
  border-color: BLACK;

  line-height: 100px;
  text-align: center;

  font-size: xx-large;
}

.checkmark{
  float: right;
  width: 10%;
  height: 100%;

  line-height: 100px;
  text-align: center;
}

.checkmark_img{
  height: 100%;
}

.checkmark_img:hover{
  height: 102%;
}

li{
  list-style-type: none;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

</style>

