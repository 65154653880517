<template>
  <div id="app">
      <itemList></itemList>
  </div>

</template>

<script>

export default {
  name: 'shopList'
}

</script>